var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("Master-Invoice", {
                    attrs: { propafes: this.afes, propinvoice: this.invoice }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "clearfix" },
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              vwref: _vm.vwref,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c(
            "div",
            { staticClass: "mt-2 invoice-status" },
            [_c("InvoiceStatus", { attrs: { invoice: _vm.invoice } })],
            1
          ),
          _c(
            "div",
            [
              !_vm.fromexternal
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.master-invoice-modal",
                          modifiers: { "master-invoice-modal": true }
                        }
                      ],
                      staticClass: "pull-right pl-3 btn-sm font-weight-bold",
                      attrs: {
                        disabled: _vm.afes.length < 1,
                        variant: "primary"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mt-2 d-none d-sm-inline mr-1" },
                        [_vm._v("View Master Invoice ")]
                      ),
                      _c("i", { staticClass: "fas fa-search" })
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [_c("VendorDetails", { attrs: { invoice: _vm.invoice } })],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("InvoiceDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      vwref: _vm.vwref,
                      highestAfe: _vm.highestAfe
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("h3", { staticClass: "d-inline-block" }, [_vm._v("Services")]),
          _vm.invoice.accountant_paid === 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger float-right",
                  attrs: { type: "button", name: "button" },
                  on: {
                    click: function($event) {
                      return _vm.removeAll()
                    }
                  }
                },
                [
                  _c("strong", [
                    _vm._v("Remove All AFES "),
                    _c("i", { staticClass: "fa fa-times" })
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "table-responsive-sm" },
            [
              _c("rotate-square2", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: { id: "loading-gif" }
              }),
              _c(
                "table",
                { staticClass: "table wss-invoice-table wss-striped" },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      { staticClass: "service-rows service-table-headers" },
                      [
                        _c(
                          "th",
                          {
                            staticClass: "center service-name",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("name")
                              }
                            }
                          },
                          [_vm._v("Service")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "center service-name",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("code")
                              }
                            }
                          },
                          [_vm._v("Code")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "datepickers right",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("date")
                              }
                            }
                          },
                          [_vm._v("Start Date - End Date")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "uom-select",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("uom")
                              }
                            }
                          },
                          [
                            _vm._v("UOM "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "fa fa-question-circle mr-1",
                              attrs: { title: "Unit Of Measurement" }
                            })
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "price center",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("price")
                              }
                            }
                          },
                          [_vm._v("Price/Unit")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "price center",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("price")
                              }
                            }
                          },
                          [_vm._v("Discounted Price/Unit")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "price center",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("quantity")
                              }
                            }
                          },
                          [_vm._v("Quantity")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "price center",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("discount")
                              }
                            }
                          },
                          [_vm._v("Discount")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "right",
                            on: {
                              click: function($event) {
                                return _vm.sortServices("total")
                              }
                            }
                          },
                          [_vm._v("Total")]
                        ),
                        _c("th")
                      ]
                    )
                  ]),
                  _vm._l(_vm.unassigned, function(service) {
                    return _c("tbody", [
                      _c(
                        "tr",
                        {
                          staticClass:
                            "service-rows mt-2 service-bg unassigned rt-wss-create-afe"
                        },
                        [
                          _c(
                            "td",
                            { staticClass: "center price v-align-middle" },
                            [
                              _c(
                                "h4",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Service: " +
                                      _vm._s(service.name) +
                                      " " +
                                      _vm._s(service.vsid)
                                  )
                                ]
                              ),
                              _c(
                                "h5",
                                { staticClass: "mb-0 d-none d-xl-block" },
                                [_vm._v(_vm._s(service.name))]
                              )
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "center price v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [_vm._v("Code: " + _vm._s(service.type))]
                              ),
                              _c("span", { staticClass: "d-none d-xl-block" }, [
                                _vm._v(_vm._s(service.type))
                              ])
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "center price v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [_vm._v("Start - End Date")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      service.start_date,
                                      "MM/DD/YYYY"
                                    )
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      service.end_date,
                                      "MM/DD/YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _c(
                                "span",
                                { staticStyle: { "font-size": "10px" } },
                                [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        parseInt(
                                          _vm
                                            .$moment(service.end_date)
                                            .diff(
                                              _vm.$moment(service.start_date),
                                              "days"
                                            )
                                        ) + 1
                                      ) +
                                      " Days)"
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "left price v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Unit Of Measurement: " +
                                      _vm._s(service.uom)
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "d-none d-xl-block" }, [
                                _vm._v(_vm._s(service.uom))
                              ])
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "price left v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Price: $" +
                                      _vm._s(_vm.formatPrice(service.pu))
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "d-none d-xl-block" }, [
                                _vm._v(
                                  "$" + _vm._s(_vm.formatPrice(service.pu))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "price left v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Discounted Price/Unit:\n                " +
                                      _vm._s(
                                        service.discount
                                          ? "$" +
                                              _vm._discount_price_per_unit(
                                                service.discount,
                                                service.pu
                                              )
                                          : service.name
                                          ? "$" + _vm.formatPrice(service.pu)
                                          : null
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "d-none d-xl-block" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      service.discount
                                        ? "$" +
                                            _vm._discount_price_per_unit(
                                              service.discount,
                                              service.pu
                                            )
                                        : service.name
                                        ? "$" + _vm.formatPrice(service.pu)
                                        : null
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "price left v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Quantity: " +
                                      _vm._s(parseFloat(service.quantity))
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-xl-inline-block" },
                                [_vm._v(_vm._s(parseFloat(service.quantity)))]
                              ),
                              /day|Day/.test(service.uom) &&
                              _vm
                                .$moment(service.end_date)
                                .diff(_vm.$moment(service.start_date), "days") +
                                1 !=
                                service.quantity
                                ? _c("span", { staticClass: "ml-1" }, [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true }
                                        }
                                      ],
                                      staticClass: "fa fa-exclamation-triangle",
                                      attrs: {
                                        title:
                                          "Quantity does not equal sum between start and end dates (" +
                                          (parseInt(
                                            _vm
                                              .$moment(service.end_date)
                                              .diff(
                                                _vm.$moment(service.start_date),
                                                "days"
                                              )
                                          ) +
                                            1) +
                                          ")!"
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "price left v-align-middle" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Discount: " +
                                      _vm._s(
                                        _vm.formatPrice(service.discount)
                                      ) +
                                      "%"
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "d-none d-xl-block" }, [
                                _vm._v(
                                  _vm._s(_vm.formatPrice(service.discount)) +
                                    "%"
                                )
                              ])
                            ]
                          ),
                          _c("td", { staticClass: "right v-align-middle" }, [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v(
                                "Total: $" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      service.quantity * service.pu -
                                        (service.discount / 100) *
                                          service.quantity *
                                          service.pu
                                    )
                                  )
                              )
                            ]),
                            _c("span", { staticClass: "d-none d-xl-block" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      service.quantity * service.pu -
                                        (service.discount / 100) *
                                          service.quantity *
                                          service.pu
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c(
                            "td",
                            [
                              _vm.invoice.accountant_paid === 0
                                ? _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "m-md-2 wss-action-dropdown",
                                      attrs: {
                                        variant: "primary",
                                        size: "sm",
                                        right: "",
                                        disabled: _vm.disableEdits == 1,
                                        text: "Select Action"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addNewUnassignedAfeLine(
                                                service,
                                                service
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("AFE "),
                                          _c("i", {
                                            staticClass:
                                              "ml-1 fa fa-plus-circle"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          staticClass: "rt-wss-create-afe",
                          class: {
                            createAfe:
                              service != _vm.createdUnassignedServiceLine
                          }
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "p-0 show",
                              attrs: { colspan: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "table-responsive-sm" },
                                [
                                  _c(
                                    "table",
                                    { staticClass: "table no-bg afenum mb-0" },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "rt-border" },
                                        [
                                          _c(
                                            "tr",
                                            { staticClass: "afe-rows" },
                                            [
                                              _c("th", {
                                                staticClass: "actions fit"
                                              }),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v("AFE / PO")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v("% "),
                                                    _c("i", {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover",
                                                          modifiers: {
                                                            hover: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "fa fa-question-circle",
                                                      attrs: {
                                                        title: "AFE Percentage"
                                                      }
                                                    })
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Cost Code #1 / Major"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Cost Code #2 / Minor"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Cost Code #3 / Description"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c("th", {
                                                staticClass: "center"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "fit btn btn-sm btn-danger",
                                                attrs: {
                                                  type: "button",
                                                  title: "AFE Cancel"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cancelAfe()
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-times-circle"
                                                })
                                              ]
                                            )
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [_vm._v("AFE / PO")]
                                              ),
                                              service ==
                                              _vm.createdUnassignedServiceLine
                                                ? _c("v-select", {
                                                    attrs: {
                                                      label: "afenum",
                                                      options:
                                                        _vm.createAfeData,
                                                      value: _vm.afenum
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.getcc1Create()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.createAfenum,
                                                      callback: function($$v) {
                                                        _vm.createAfenum = $$v
                                                      },
                                                      expression: "createAfenum"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c("td", [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "d-xs-block d-xl-none"
                                              },
                                              [_vm._v("Percentage")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.percentage,
                                                  expression: "percentage"
                                                }
                                              ],
                                              staticClass: "rt-percent",
                                              attrs: {
                                                type: "number",
                                                step: "any"
                                              },
                                              domProps: {
                                                value: _vm.percentage
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.percentage =
                                                    $event.target.value
                                                }
                                              }
                                            })
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [_vm._v("Cost Code #1 / Major")]
                                              ),
                                              service ==
                                              _vm.createdUnassignedServiceLine
                                                ? _c("v-select", {
                                                    attrs: {
                                                      label: "ccone_code",
                                                      options:
                                                        _vm.cc1CreateData,
                                                      value: _vm.ccone_code
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.getcc2Create()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.createCC1Code,
                                                      callback: function($$v) {
                                                        _vm.createCC1Code = $$v
                                                      },
                                                      expression:
                                                        "createCC1Code"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [_vm._v("Cost Code #2 / Minor")]
                                              ),
                                              service ==
                                              _vm.createdUnassignedServiceLine
                                                ? _c("v-select", {
                                                    attrs: {
                                                      label: "cctwo_code",
                                                      options:
                                                        _vm.cc2CreateData,
                                                      value: _vm.cctwo_code
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.getcc3Create()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.createCC2Code,
                                                      callback: function($$v) {
                                                        _vm.createCC2Code = $$v
                                                      },
                                                      expression:
                                                        "createCC2Code"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [
                                                  _vm._v(
                                                    "Cost Code #3 / Description"
                                                  )
                                                ]
                                              ),
                                              service ==
                                              _vm.createdUnassignedServiceLine
                                                ? _c("v-select", {
                                                    attrs: {
                                                      label: "ccthree_code",
                                                      options:
                                                        _vm.cc3CreateData,
                                                      value: _vm.ccthree_code
                                                    },
                                                    model: {
                                                      value: _vm.createCC3Code,
                                                      callback: function($$v) {
                                                        _vm.createCC3Code = $$v
                                                      },
                                                      expression:
                                                        "createCC3Code"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c("td", { staticClass: "actions" }, [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm mt-1 btn-success mr-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addAfeUnassignedLine(
                                                      service
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v("Add "),
                                                _c("i", {
                                                  staticClass: "fa fa-plus"
                                                })
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm mt-1 btn-success",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addAfeToAllInvoiceServices(
                                                      service
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v("Add To All "),
                                                _c("i", {
                                                  staticClass: "fa fa-plus"
                                                })
                                              ]
                                            )
                                          ])
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  }),
                  _vm._l(_vm.merged, function(afe, index) {
                    return _c(
                      "tbody",
                      [
                        _vm._l(afe, function(service) {
                          return _c(
                            "tr",
                            {
                              staticClass:
                                "service-rows mt-2 service-bg rt-wss-create-afe"
                            },
                            [
                              _c(
                                "td",
                                { staticClass: "center price v-align-middle" },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Service: " +
                                          _vm._s(service.name) +
                                          " " +
                                          _vm._s(service.vsid)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "h5",
                                    { staticClass: "mb-0 d-none d-xl-block" },
                                    [_vm._v(_vm._s(service.name))]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "center price v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [_vm._v("Code: " + _vm._s(service.type))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [_vm._v(_vm._s(service.type))]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "center price v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [_vm._v("Start - End Date")]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          service.start_date,
                                          "MM/DD/YYYY"
                                        )
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          service.end_date,
                                          "MM/DD/YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "10px" } },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(
                                            parseInt(
                                              _vm
                                                .$moment(service.end_date)
                                                .diff(
                                                  _vm.$moment(
                                                    service.start_date
                                                  ),
                                                  "days"
                                                )
                                            ) + 1
                                          ) +
                                          " Days)"
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "left price v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Unit Of Measurement: " +
                                          _vm._s(service.uom)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [_vm._v(_vm._s(service.uom))]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "price left v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Price: $" +
                                          _vm._s(_vm.formatPrice(service.pu))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.formatPrice(service.pu))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "price left v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Discounted Price/Unit:\n                " +
                                          _vm._s(
                                            service.discount
                                              ? "$" +
                                                  _vm._discount_price_per_unit(
                                                    service.discount,
                                                    service.pu
                                                  )
                                              : service.name
                                              ? "$" + service.pu
                                              : null
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            service.discount
                                              ? "$" +
                                                  _vm._discount_price_per_unit(
                                                    service.discount,
                                                    service.pu
                                                  )
                                              : service.name
                                              ? "$" + service.pu
                                              : null
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "price left v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Quantity: " +
                                          _vm._s(parseFloat(service.quantity))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-inline-block" },
                                    [
                                      _vm._v(
                                        _vm._s(parseFloat(service.quantity))
                                      )
                                    ]
                                  ),
                                  /day|Day/.test(service.uom) &&
                                  _vm
                                    .$moment(service.end_date)
                                    .diff(
                                      _vm.$moment(service.start_date),
                                      "days"
                                    ) +
                                    1 !=
                                    service.quantity
                                    ? _c("span", { staticClass: "ml-1" }, [
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass:
                                            "fa fa-exclamation-triangle",
                                          attrs: {
                                            title:
                                              "Quantity does not equal sum between start and end dates (" +
                                              (parseInt(
                                                _vm
                                                  .$moment(service.end_date)
                                                  .diff(
                                                    _vm.$moment(
                                                      service.start_date
                                                    ),
                                                    "days"
                                                  )
                                              ) +
                                                1) +
                                              ")!"
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "price left v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Discount: " +
                                          _vm._s(
                                            _vm.formatPrice(service.discount)
                                          ) +
                                          "%"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPrice(service.discount)
                                        ) + "%"
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "right v-align-middle" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "d-xs-block d-xl-none" },
                                    [
                                      _vm._v(
                                        "Total: $" +
                                          _vm._s(
                                            _vm.formatPrice(
                                              service.quantity * service.pu -
                                                (service.discount / 100) *
                                                  service.quantity *
                                                  service.pu
                                            )
                                          )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.formatPrice(
                                              service.quantity * service.pu -
                                                (service.discount / 100) *
                                                  service.quantity *
                                                  service.pu
                                            )
                                          )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                [
                                  _vm.invoice.accountant_paid === 0
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          staticClass:
                                            "m-md-2 wss-action-dropdown",
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                            right: "",
                                            disabled: _vm.disableEdits == 1,
                                            text: "Select Action"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNewAfeLine(
                                                    afe,
                                                    service
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v("AFE "),
                                              _c("i", {
                                                staticClass:
                                                  "ml-1 fa fa-plus-circle"
                                              })
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeAllAfe(
                                                    service
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Remove All Assigned AFES "
                                              ),
                                              _c("i", {
                                                staticClass: "ml-1 fa fa-times"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "p-0", attrs: { colspan: "100%" } },
                            [
                              _c(
                                "div",
                                { staticClass: "table-responsive-sm" },
                                [
                                  _c(
                                    "table",
                                    { staticClass: "table no-bg afenum mb-0" },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "rt-border" },
                                        [
                                          _c(
                                            "tr",
                                            { staticClass: "afe-rows" },
                                            [
                                              _c("th", {
                                                staticClass: "actions fit"
                                              }),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v("AFE / PO")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center fit" },
                                                [
                                                  _c("strong", [
                                                    _vm._v("% "),
                                                    _c("i", {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover",
                                                          modifiers: {
                                                            hover: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "fa fa-question-circle",
                                                      attrs: {
                                                        title: "AFE Percentage"
                                                      }
                                                    })
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Cost Code #1 / Major"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Cost Code #2 / Minor"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Cost Code #3 / Description"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c("th", {
                                                staticClass: "centerx"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        [
                                          _vm._l(afe[0].afes, function(
                                            line,
                                            aIndex
                                          ) {
                                            return _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "rt-wss-create-afe rt-mobile-indent",
                                                class: {
                                                  editingAfe:
                                                    line == _vm.editedAfeLine
                                                }
                                              },
                                              [
                                                _c("td", {
                                                  staticClass: "remove-button",
                                                  staticStyle: {
                                                    "border-top": "none"
                                                  },
                                                  attrs: { border: "0" }
                                                }),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "view",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          ;(_vm.editAfe = 1),
                                                            _vm.editAfeLine(
                                                              line,
                                                              index,
                                                              aIndex,
                                                              _vm.afes,
                                                              afe
                                                            )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "d-block d-xl-none"
                                                        },
                                                        [_vm._v("AFE")]
                                                      ),
                                                      _vm.invoice
                                                        .accountant_paid === 0
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "fit btn btn-danger float-right d-inline-block d-xl-none ml-2",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disableEdits ==
                                                                  1,
                                                                type: "button"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeAllAfe(
                                                                    afe[0].afes
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-minus-circle"
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.invoice
                                                        .accountant_paid === 0
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-primary d-inline-block d-xl-none float-right",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  ;(_vm.editAfe = 1),
                                                                    _vm.editAfeLine(
                                                                      line,
                                                                      index,
                                                                      aIndex,
                                                                      _vm.afes,
                                                                      afe
                                                                    )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-edit"
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-chevron-circle-right"
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(line.afenum) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "edit" },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [_vm._v("AFE / PO")]
                                                      ),
                                                      line == _vm.editedAfeLine
                                                        ? _c("v-select", {
                                                            attrs: {
                                                              label: "afenum",
                                                              options:
                                                                _vm.serviceChainData,
                                                              value: _vm.afenum
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.getcc1(
                                                                  index,
                                                                  aIndex
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                line.afenum,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  line,
                                                                  "afenum",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "line.afenum"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "view",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          ;(_vm.editAfe = 1),
                                                            _vm.editAfeLine(
                                                              line,
                                                              index,
                                                              aIndex,
                                                              _vm.afes,
                                                              afe
                                                            )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [_vm._v("Percentage")]
                                                      ),
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            line.percentage
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "edit" },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [_vm._v("Percentage")]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.percentage,
                                                            expression:
                                                              "percentage"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "rt-percent",
                                                        attrs: {
                                                          type: "number",
                                                          step: "any"
                                                        },
                                                        domProps: {
                                                          value: _vm.percentage
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.percentage =
                                                              $event.target.value
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "view",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          ;(_vm.editAfe = 1),
                                                            _vm.editAfeLine(
                                                              line,
                                                              index,
                                                              aIndex,
                                                              _vm.afes,
                                                              afe
                                                            )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cost Code #1 / Major"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            line.ccone_code
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "edit" },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cost Code #1 / Major"
                                                          )
                                                        ]
                                                      ),
                                                      line == _vm.editedAfeLine
                                                        ? _c("v-select", {
                                                            attrs: {
                                                              label:
                                                                "ccone_code",
                                                              options:
                                                                _vm.cc1Data,
                                                              value:
                                                                _vm.ccone_code
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.getcc2(
                                                                  index,
                                                                  aIndex
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                line.ccone_code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  line,
                                                                  "ccone_code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "line.ccone_code"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "view",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          ;(_vm.editAfe = 1),
                                                            _vm.editAfeLine(
                                                              line,
                                                              index,
                                                              aIndex,
                                                              _vm.afes,
                                                              afe
                                                            )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cost Code #2 / Minor"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            line.cctwo_code
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "edit" },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cost Code #2 / Minor"
                                                          )
                                                        ]
                                                      ),
                                                      line == _vm.editedAfeLine
                                                        ? _c("v-select", {
                                                            attrs: {
                                                              label:
                                                                "cctwo_code",
                                                              options:
                                                                _vm.cc2Data,
                                                              value:
                                                                _vm.cctwo_code
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.getcc3(
                                                                  index,
                                                                  aIndex
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                line.cctwo_code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  line,
                                                                  "cctwo_code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "line.cctwo_code"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "view",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          ;(_vm.editAfe = 1),
                                                            _vm.editAfeLine(
                                                              line,
                                                              index,
                                                              aIndex,
                                                              _vm.afes,
                                                              afe
                                                            )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cost Code #3 / Description"
                                                          )
                                                        ]
                                                      ),
                                                      typeof line.ccthree_code ==
                                                        "string" &&
                                                      !line.ccthree_code.includes(
                                                        "---"
                                                      )
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                line.ccthree_code
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "edit" },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "d-xs-block d-xl-none"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cost Code #3 / Description"
                                                          )
                                                        ]
                                                      ),
                                                      line == _vm.editedAfeLine
                                                        ? _c("v-select", {
                                                            attrs: {
                                                              label:
                                                                "ccthree_code",
                                                              options:
                                                                _vm.cc3Data,
                                                              value:
                                                                _vm.ccthree_code
                                                            },
                                                            model: {
                                                              value:
                                                                line.ccthree_code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  line,
                                                                  "ccthree_code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "line.ccthree_code"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]),
                                                _c(
                                                  "td",
                                                  { staticClass: "actions" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "edit" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              type: "button"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editAddAfeToInvoice(
                                                                  line,
                                                                  afe,
                                                                  index,
                                                                  aIndex,
                                                                  _vm.afes
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-edit"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          _c(
                                            "tr",
                                            {
                                              staticClass: "rt-wss-create-afe",
                                              class: {
                                                createAfe:
                                                  afe != _vm.createdServiceLine
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "show" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover",
                                                          modifiers: {
                                                            hover: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "fit btn btn-sm btn-danger",
                                                      attrs: {
                                                        type: "button",
                                                        title: "AFE Cancel"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.cancelAfe()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times-circle"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "show" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [_vm._v("AFE / PO")]
                                                    ),
                                                    afe ==
                                                    _vm.createdServiceLine
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            label: "afenum",
                                                            options:
                                                              _vm.createAfeData,
                                                            value: _vm.afenum
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.getcc1Create()
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.createAfenum,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.createAfenum = $$v
                                                            },
                                                            expression:
                                                              "createAfenum"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "show" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [_vm._v("Percentage")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.percentage,
                                                          expression:
                                                            "percentage"
                                                        }
                                                      ],
                                                      staticClass: "rt-percent",
                                                      attrs: {
                                                        type: "number",
                                                        step: "any"
                                                      },
                                                      domProps: {
                                                        value: _vm.percentage
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.percentage =
                                                            $event.target.value
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "show" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cost Code #1 / Major"
                                                        )
                                                      ]
                                                    ),
                                                    afe ==
                                                    _vm.createdServiceLine
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            label: "ccone_code",
                                                            options:
                                                              _vm.cc1CreateData,
                                                            value:
                                                              _vm.ccone_code
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.getcc2Create()
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.createCC1Code,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.createCC1Code = $$v
                                                            },
                                                            expression:
                                                              "createCC1Code"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "show" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cost Code #2 / Minor"
                                                        )
                                                      ]
                                                    ),
                                                    afe ==
                                                    _vm.createdServiceLine
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            label: "cctwo_code",
                                                            options:
                                                              _vm.cc2CreateData,
                                                            value:
                                                              _vm.cctwo_code
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.getcc3Create()
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.createCC2Code,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.createCC2Code = $$v
                                                            },
                                                            expression:
                                                              "createCC2Code"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "show" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "d-xs-block d-xl-none"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cost Code #3 / Description"
                                                        )
                                                      ]
                                                    ),
                                                    afe ==
                                                    _vm.createdServiceLine
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            label:
                                                              "ccthree_code",
                                                            options:
                                                              _vm.cc3CreateData,
                                                            value:
                                                              _vm.ccthree_code
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.createCC3Code,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.createCC3Code = $$v
                                                            },
                                                            expression:
                                                              "createCC3Code"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _c(
                                                "td",
                                                { staticClass: "actions" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "show" },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-success mr-2",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addAfeLine(
                                                                afe
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v("Add "),
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-plus"
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-success",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addAfeToAllInvoiceServices(
                                                                index,
                                                                afe
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v("Add To All "),
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-plus"
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("InvoiceTotals", {
            attrs: {
              invoice: _vm.invoice,
              serviceData: _vm.serviceData,
              currency: _vm.currency
            }
          }),
          _vm.set === 1
            ? _c("div", [
                _c("h5", [
                  _vm._v("Comments "),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "ml-2 fa fa fa-question-circle",
                    attrs: {
                      title:
                        "Comments will be seen by all user levels and be attached on invoice"
                    }
                  })
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.comments,
                      expression: "comments"
                    }
                  ],
                  staticClass: "w-100 rounded border mt-2 mb-3",
                  attrs: { name: "comments", id: "", rows: "3" },
                  domProps: { value: _vm.comments },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.comments = $event.target.value
                    }
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger mr-4 mb-4",
                    on: {
                      click: function($event) {
                        return _vm.submitInvoice(2)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-times-circle" }),
                    _vm._v(" Reject")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success mb-4",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        return _vm.submitInvoice(1)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-check" }), _vm._v(" Approve")]
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceError,
                  expression: "invoiceError"
                }
              ],
              staticClass: "d-block"
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invoiceError,
                    expression: "invoiceError"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceError,
                      expression: "invoiceError"
                    }
                  ],
                  staticClass: "help"
                },
                [_vm._v("Invoice Error. Please fix invoice or contact admin.")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-card",
        [_c("InvoiceHistory", { attrs: { history: _vm.history } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }